import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import {
  GridRowModes,
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import ImageUploader from "../components/ImgUploadDialog";
import CusPopover from "../components/Popover";
import SentDeliveryDialoag from "../components/SentDeliveryDialoag";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const Stitching = ({ authToken }) => {
  const [rowModesModel, setRowModesModel] = useState({});
  const navigate = useNavigate();
  const [fabricsData, setFabricsData] = useState([]);
  const [embUnits, setEmbUnits] = useState([]);
  const [fabricId, setFabricId] = useState("");
  const [img, setImg] = useState("");
  const [usersNames, setUsersNames] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [sentPieces, setSentPieces] = useState("");
  const [stitchId, setStitchId] = useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const ToggleOpen = () => {
    setOpen(!open);
  };
  const ToggleOpenDelivery = (id) => {
    setOpenDelivery(!openDelivery);
    setStitchId(id);
  };
  const handelSentPieces = (value) => {
    setSentPieces(value);
  };

  useEffect(() => {
    api
      .get("stitching", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setFabricsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    api
      .get("units/isactive", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        const options = response.data.map((item) => item.unit_name);
        setUsersNames(options);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    api
      .get("units/emb/isactive", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        const options = response.data.map((item) => item.unit_name);
        setEmbUnits(options);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [authToken]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    try {
      const response = await api.put(
        `stitching/${newRow.id}`,
        { newRow },
        {
          headers: {
            token: authToken,
          },
        }
      );

      setSnackbar({ children: response.data, severity: "success" });
    } catch (error) {
      console.error("Error making request:", error);
      setSnackbar({ children: error.response.data, severity: "error" });
    }

    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleOpenClick = (id) => {
    navigate(`/fabricdetail/${id}`);
  };
  const OpenUnitDetails = (id) => {
    if (id) {
      navigate(`/unitdetail/${id}`);
    }
  };
  const OpenEmbUnitDetails = (id) => {
    console.log(id);
    if (id) {
      navigate(`/embunitdetail/${id}`);
    }
  };
  const openImage = (fabric_id, imgs) => {
    setImg(imgs);
    setFabricId(fabric_id);
    ToggleOpen();
  };
  const deleteButton = (id) => async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this fabric?"
    );
    if (confirm) {
      setFabricsData(fabricsData.filter((row) => row.id !== id));
      const [row] = fabricsData.filter((row) => row.id == id);

      try {
        const response = await api.delete(`stitching/${row.fabric_id}`, {
          headers: {
            token: authToken,
          },
        });

        setSnackbar({ children: response.data, severity: "success" });
      } catch (error) {
        console.error("Error making request:", error);
        setSnackbar({ children: error.response.data, severity: "error" });
      }
    } else {
      setSnackbar({
        children: "operation canceled on your request",
        severity: "error",
      });
    }
  };
  const SendDelivery = async () => {
    if (stitchId && sentPieces > 0) {
      try {
        const response = await api.post(
          `units/delivery`,
          { stitch_id: stitchId, sent_pieces: sentPieces },
          {
            headers: {
              token: authToken,
            },
          }
        );
        setSnackbar({
          children: response.data,
          severity: "success",
        });
        ToggleOpenDelivery();
        setSentPieces("");
        navigate("/delivery");
      } catch (error) {
        console.error("Error making request:", error);
        setSnackbar({ children: error.response.data, severity: "error" });
      }
    } else {
      setSnackbar({
        children: "Please Add Some Quantity...",
        severity: "error",
      });
    }
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_img",
      width: 70,
      type: "image",
      headerName: "Image",
      renderCell: (params) => (
        <span
          onClick={() =>
            openImage(params.row.fabric_id, params.row.product_img)
          }
        >
          <img
            src={params.value}
            style={{ width: 30, height: 60 }}
            alt="Product"
          />
        </span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "fabric_id",
      headerName: "Fabric ID",
      width: 130,
      align: "center",
      renderCell: (params) => (
        <span onClick={() => handleOpenClick(params.row.fabric_id)}>
          <i className="bi bi-chevron-right"></i>
          {params.value}
        </span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "for_article",
      headerName: "ART#",
      width: 200,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "unit_name",
      headerName: "Unit",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: usersNames,
      align: "center",
      renderCell: (params) => (
        <span onClick={() => OpenUnitDetails(params.row.unit_id)}>
          <i className="bi bi-chevron-right"></i>
          {params.value}
        </span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "status",
      headerName: "status",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Halt",
        "Cutting",
        "Sewing",
        "Press",
        "Cheking",
        "Packing",
        "Audit",
      ],
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "pieces",
      type: "number",
      headerName: "CPieces",
      width: 150,
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "pending_delivery",
      type: "number",
      headerName: "Pending",
      width: 150,
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "isEmbroidered",
      type: "boolean",
      headerName: "Embroidered",
      width: 140,
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "embroidery_status",
      headerName: "Embroidery Status",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Pending", "On Embroidery", "Done"],
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "embd_vendor",
      headerName: "EMBD Vendor",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: embUnits,
      align: "center",
      renderCell: (params) => (
        <span onClick={() => OpenEmbUnitDetails(params.row.embunit_id)}>
          <i className="bi bi-chevron-right"></i>
          {params.value}
        </span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "remarks",
      headerName: "Remarks",
      width: 150,
      editable: true,
      type: "text",
      align: "center",

      renderCell: (params) => <CusPopover cellValue={params.value} />,
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "createdAt",
      headerName: "Date",
      width: 100,
      align: "center",
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "updatedAt",
      headerName: "UpDate",
      width: 100,
      align: "center",
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 140,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={deleteButton(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<FileOpenIcon />}
            label="Dispatch"
            onClick={() => ToggleOpenDelivery(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="container-fluid mt-1">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <ImageUploader
        key={Date.now()}
        ToggleOpen={ToggleOpen}
        open={open}
        fabricID={fabricId}
        img={img}
        authToken={authToken}
      />
      <SentDeliveryDialoag
        ToggleOpen={ToggleOpenDelivery}
        open={openDelivery}
        SendDelivery={() => SendDelivery()}
        value={sentPieces}
        onChange={handelSentPieces}
      />

      <div className="d-flex justify-content-center">
        <h6 className="fw-bold shadow-sm p-2 mb-2 rounded bg-body">
          Stitching Units
        </h6>
      </div>

      <div style={{ height: "85vh", overflowX: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              backgroundColor: "rgba(42, 255, 0, 0.5)",
            },
          }}
          rowHeight={30}
          rows={fabricsData}
          columns={columns}
          editMode="row"
          getRowId={(row) => row.id}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing row update:", error);
            setSnackbar({ children: error.message, severity: "error" });
          }}
        />
      </div>
    </div>
  );
};

export default Stitching;

import { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const UnitReg = ({ setSnackbar, endpoint, ToggleOpen, open, authToken }) => {
  const [formData, setFormData] = useState({
    unit_name: "",
    address: "",
    owner_name: "",
    isActive: "",
  });
  const resetForm = () => {
    setFormData({
      unit_name: "",
      address: "",
      owner_name: "",
    });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const viewUnits = () => {
    resetForm();
    ToggleOpen();
  };
  const handlRegister = async (e) => {
    e.preventDefault();
    try {
      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "application/json",
          token: authToken,
        },
      });
      setSnackbar({
        children: "Unit Regestered successfully!",
        severity: "success",
      });
      ToggleOpen();
    } catch (error) {
      console.error("reg failed:", error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={() => ToggleOpen()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Register A Vendor"} </DialogTitle>

        <DialogContent>
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary float-end"
            onClick={viewUnits}
          >
            <i className="bi bi-eye"></i> &nbsp; View Units
          </button>
          <div className="shadow-sm p-2 mb-2 bg-body rounded ">
            <form onSubmit={handlRegister}>
              <div className="">
                <label htmlFor="unit_name" className="form-label ml-2">
                  Unit Name
                  <input
                    required
                    autoComplete="off"
                    className="form-control "
                    type="text"
                    placeholder="Unit Name...."
                    name="unit_name"
                    value={formData.unit_name}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="">
                <label htmlFor="address" className="form-label">
                  Address
                  <input
                    required
                    autoComplete="off"
                    className="form-control"
                    type="text"
                    placeholder="address...."
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="mb-3">
                <label htmlFor="owner_name" className="form-label">
                  Owner Name
                  <input
                    required
                    autoComplete="off"
                    className="form-control"
                    type="text"
                    placeholder="owner_name"
                    name="owner_name"
                    value={formData.owner_name}
                    onChange={handleChange}
                  />
                </label>
              </div>

              <div className="mb-3">
                <button className=" btn btn-success" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => ToggleOpen()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UnitReg;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import FabricCard from "../components/FabricCard";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const FabricDetail = ({ authToken }) => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [stitchData, setStitchData] = useState();
  const [loading, setLoading] = useState(true);

  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const fetchData = async () => {
    try {
      const response = await api.get(`fabrics/details/${id}`, {
        headers: {
          token: authToken,
        },
      });
      setData(response.data.fabric);
      setStitchData(response.data.stitch);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div className="container">
        <p>loading....</p>
      </div>
    );
  }
  return (
    <div className="container-fluid m-3 shadow p-3 mb-5 bg-body rounded ">
      <FabricCard fabric={data} stitch={stitchData} />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
};

export default FabricDetail;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import UserRegForm from "../components/UserRegForm";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const RegesterPage = ({ authToken }) => {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    username: "",
    password: "",
    repassword: "",
    passwordError: "",
  });
  const resetForm = () => {
    setFormData({
      fullName: "",
      mobile: "",
      username: "",
      password: "",
      repassword: "",
      passwordError: "",
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    // Add validation for password and repassword
    if (name === "password" || name === "repassword") {
      if (name === "password" && value !== formData.repassword) {
        error = "Passwords do not match";
      } else if (name === "repassword" && value !== formData.password) {
        error = "Passwords do not match";
      }
    }

    setFormData({ ...formData, [name]: value, passwordError: error });
  };
  const viewUsers = () => {
    resetForm();
    navigate(`/users`);
  };

  const handlRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("auths/register", formData, {
        headers: {
          "Content-Type": "application/json",
          token: authToken,
        },
      });
      setSnackbar({
        children: response.data,
        severity: "success",
      });
      viewUsers();
    } catch (error) {
      console.error("reg failed:", error);
    }
  };
  return (
    <div>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="shadow p-3 mb-5 bg-body rounded">
          <h1>Register Page</h1>
          <div className="">
            <UserRegForm
              formData={formData}
              handleChange={handleChange}
              handleSubmit={handlRegister}
              viewUsers={viewUsers}
            />
            {formData.repassword && formData.passwordError && (
              <p className="alert alert-danger">{formData.passwordError}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegesterPage;

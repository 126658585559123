import React from "react";
import { Link } from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

const FabricCard = ({ fabric, stitch }) => {
  const {
    id,
    issued_by,
    issued_to,
    meters,
    for_article,
    createdAt,
    updatedAt,

    cuttingToSewingDiff,
    sewingToPressDiff,
  } = fabric;
  const {
    fabric_id,
    unit_id,
    unit_name,
    pieces,
    status,
    isEmbroidered,
    embroidery_status,
    cutting_date,
    sewing_date,
    press_date,
    cheking_date,
    packing_date,
    audit_date,
  } = stitch;
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <div className=" d-flex  flex-nowrap">
      <div className="card-body ">
        <div className="shadow-sm p-2 mb-2 rounded bg-body">
          <h5 className="card-title">Fabric Details of {fabric_id}</h5>
          <span>ART# = {for_article}</span>
          <span> - Issued By = {issued_by}</span>
          <span> - Issued To = {issued_to}</span>
          <span> - Meters = {meters}</span>
        </div>
        <p>Unit ID: {unit_id}</p>
        <p>Unit Name: {unit_name}</p>
        <p>Pieces: {pieces}</p>
        <p>Status: {status}</p>
        <p>
          Is Embroidered:
          <span
            className={
              isEmbroidered ? "alert alert-success" : "alert alert-info"
            }
          >
            {isEmbroidered ? "Yes" : "No"}
          </span>{" "}
        </p>
        <p>Embroidery Status: {embroidery_status}</p>
        <p className="alert alert-info">Created At: {formatDate(createdAt)}</p>
        <p>Updated At: {formatDate(updatedAt)}</p>
        <p>Cutting Date: {formatDate(cutting_date)}</p>
        <p>Sewing Date: {formatDate(sewing_date)}</p>
        <p>Press Date: {press_date}</p>
        <p>Checking Date: {cheking_date}</p>
        <p>Packing Date: {packing_date}</p>
        <p>Audit Date: {audit_date}</p>
      </div>
      <div className="shadow p-3 mb-3 bg-body rounded m-3 ">
        <div className="d-flex  flex-column">
          <div className="d-flex flex-row  justify-content-between">
            <p className="fw-bold float-start">Insights</p>
            <p className="fw-lighter float-end">Last Days</p>
          </div>
          <div className="">
            <p>Cutting days to take to sewing: {cuttingToSewingDiff}</p>
            <p>Sewing time: {sewingToPressDiff}</p>
          </div>
          <div className="d-flex justify-content-between">
            <Link className="float-start">View Details</Link>
            <Fab size="small" color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FabricCard;

import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const UnitDetail = ({ authToken }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState();
  const [unitData, setUnitData] = useState();
  const [loading, setLoading] = useState(true);

  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(`units/details/${id}`, {
        headers: {
          token: authToken,
        },
      });
      setData(response.data.unit);
      setUnitData(response.data.unitData);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  }, [id, authToken]);

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  if (loading) {
    return (
      <div className="container">
        <p>loading....</p>
      </div>
    );
  }
  const handleOpenClick = (id) => {
    navigate(`/fabricdetail/${id}`);
  };
  const columns = [
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "fabric_id",
      headerName: "Fabric ID",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <span onClick={() => handleOpenClick(params.row.fabric_id)}>
          <i className="bi bi-chevron-right"></i>
          {params.value}
        </span>
      ),
    },

    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "pieces",
      type: "number",
      headerName: "CPieces",
      width: 150,
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "isEmbroidered",
      type: "boolean",
      headerName: "Embroidered",
      width: 140,
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "embroidery_status",
      type: "text",
      headerName: "Embroiderey Status",
      width: 140,
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "status",
      type: "text",
      headerName: "Status",
      width: 140,
      editable: true,
      align: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "createdAt",
      headerName: "Date",
      width: 100,
      align: "center",
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "updatedAt",
      headerName: "UpDate",
      width: 100,
      align: "center",
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),
    },
  ];
  return (
    <div className="container-fluid mt-5">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <div className="col-lg-5 col-xl-5 col-sm-10 shadow p-3 mb-5 bg-body rounded ">
        <div className="d-flex flex-wrap justify-content-evenly">
          <h6 className=""> Unit Name :{data.unit_name}</h6>
          <p className=""> Owner name :{data.owner_name}</p>
          <p className=""> Address :{data.address}</p>
        </div>
      </div>
      <div style={{ height: "80vh", overflowX: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              backgroundColor: "rgba(42, 255, 0, 0.5)",
            },
          }}
          rowHeight={25}
          rows={unitData}
          columns={columns}
          getRowId={(row) => row.id}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </div>
    </div>
  );
};

export default UnitDetail;

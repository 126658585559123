import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const SentDeliveryDialoag = ({
  SendDelivery,
  ToggleOpen,
  open,
  value,
  onChange,
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={() => ToggleOpen()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Send delivery"} </DialogTitle>

        <DialogContent>
          <div className="d-flex justify-content-between">
            <input
              required
              autoComplete="off"
              className="form-control "
              type="number"
              placeholder="Enter Pieces...."
              name="sent_pieces"
              value={value}
              onChange={handleChange}
            />

            <button
              className="ms-1 btn btn-success"
              type="button"
              onClick={SendDelivery}
            >
              Dispatch
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => ToggleOpen()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SentDeliveryDialoag;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageUploader from "../components/ImgUploadDialog";
import StoreForm from "../components/StoreForm";
import {
  GridRowModes,
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const Fabrics = ({ authToken }) => {
  const [rowModesModel, setRowModesModel] = useState({});
  const navigate = useNavigate();
  const [fabricsData, setFabricsData] = useState([]);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [fabricId, setFabricId] = useState("");
  const [img, setImg] = useState("");
  const [viewSet, setViewSet] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const [open, setOpen] = useState(false);
  const handleCloseSnackbar = () => setSnackbar(null);
  const ToggleOpen = () => {
    setOpen(!open);
  };

  const [formData, setFormData] = useState({
    issued_to: "",
    meters: "",
    for_article: "",
  });
  const selectUserId = async (value) => {
    setUserId(value);
    setFormData({
      ...formData,
      issued_to: value,
    });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    api
      .get("fabrics", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setFabricsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    api
      .get("users/all/name", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [authToken]);

  const viewFabrics = () => {
    setViewSet((prevViewSet) => !prevViewSet);
    resetForm();
    if (fabricsData.length === 0) {
      setSnackbar({
        children: "There is no store to show add  a new store",
        severity: "warning",
      });
    }
  };

  const resetForm = () => {
    setFormData({
      issued_to: "",
      meters: "",
      for_article: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post(`fabrics`, formData, {
        headers: {
          "Content-Type": "application/json",
          token: authToken,
        },
      });

      setSnackbar({
        children: response.data,
        severity: "success",
      });
      setTimeout(() => {
        resetForm();
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Submit Failed:", error);
      setSnackbar({ children: error.message, severity: "error" });
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    try {
      const response = await api.put(
        `fabrics/${newRow.id}`,
        { newRow },
        {
          headers: {
            token: authToken,
          },
        }
      );

      setSnackbar({ children: response.data, severity: "success" });
    } catch (error) {
      console.error("Error making request:", error);
    }
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleOpenClick = (id) => {
    navigate(`/fabricdetail/${id}`);
  };
  const openImage = (fabric_id, imgs) => {
    setImg(imgs);
    setFabricId(fabric_id);
    ToggleOpen();
  };
  const columns = [
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_img", // You can use a field that represents the image URL

      width: 70,
      headerName: "Image",

      renderCell: (params) => (
        <span onClick={() => openImage(params.row.id, params.row.product_img)}>
          <img
            src={params.value}
            style={{ width: 50, height: 50 }}
            alt="Product"
          />
        </span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "id",
      headerName: "ID",
      width: 130,
      align: "center",
      renderCell: (params) => (
        <span onClick={() => handleOpenClick(params.row.id)}>
          <i className="bi bi-chevron-right"></i>
          {params.value}
        </span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "for_article",
      headerName: "ART#",
      width: 200,
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "issued_by",
      headerName: "Issued by",
      width: 140,
      // editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "issued_to",
      headerName: "To",
      width: 150,
      // editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "meters",
      headerName: "Meters",
      width: 100,
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "stitch_status",
      headerName: "Unit Status",
      width: 150,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "embroidery_status",
      headerName: "EMBD Status",
      width: 130,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "stitch_pieces",
      headerName: "Pieces",
      width: 150,

      align: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "createdAt",
      headerName: "Date",
      width: 150,
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 140,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<FileOpenIcon />}
            label="Open"
            onClick={() => handleOpenClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (viewSet && fabricsData.length > 0) {
    return (
      <div className="container-fluid mt-1">
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
        <ImageUploader
          key={Date.now()}
          ToggleOpen={ToggleOpen}
          open={open}
          fabricID={fabricId}
          img={img}
          authToken={authToken}
        />

        <button
          type="button"
          className="btn btn-sm btn-outline-secondary "
          onClick={viewFabrics}
        >
          <i className="bi bi-gear"></i> &nbsp;New Fabric
        </button>
        <br />

        <div className="d-flex justify-content-center">
          <h6 className="fw-bold shadow-sm p-2 mb-2 rounded bg-body">
            Fabrics
          </h6>
        </div>

        <div style={{ height: "85vh", overflowX: "100%" }}>
          <DataGrid
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .super-app-theme--header": {
                backgroundColor: "rgba(42, 255, 0, 0.5)",
              },
            }}
            dataAlign="center"
            rowHeight={30}
            rows={fabricsData}
            columns={columns}
            editMode="row"
            getRowId={(row) => row.id}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onProcessRowUpdateError={(error) => {
              console.error("Error processing row update:", error);
              setSnackbar({ children: error.message, severity: "error" });
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="container-fluid mt-5">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <StoreForm
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        viewFabrics={viewFabrics}
        users={users}
        value={userId}
        onChange={selectUserId}
      />
    </div>
  );
};

export default Fabrics;

import React from "react";

const Select = ({ users, value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <select className="form-control" value={value} onChange={handleChange}>
      <option value="">Select</option>
      {users.map((user) => (
        <option key={user.id} value={user.id}>
          {user.full_name}
        </option>
      ))}
    </select>
  );
};

export default Select;

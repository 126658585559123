import React from "react";

function StoreForm({ formData, handleChange, handleSubmit, viewUsers }) {
  return (
    <div className="">
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary float-end"
        onClick={viewUsers}
      >
        <i className="bi bi-eye"></i> &nbsp; View Users
      </button>
      <br />
      <br />
      <p>Please enter all information correctly.</p>
      <h4 className="mb-5 text-center">Register User</h4>
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="mb-3 d-flex justify-content-around">
            <label htmlFor="fullName" className="form-label ml-2">
              Full Name
              <input
                required
                autoComplete="off"
                className="form-control "
                type="text"
                placeholder="Ful Name...."
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="mb-3">
            <label htmlFor="mobile" className="form-label">
              Mobile
            </label>

            <input
              required
              autoComplete="off"
              className="form-control"
              type="text"
              placeholder="mobile...."
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              Username
            </label>

            <input
              required
              autoComplete="off"
              className="form-control"
              type="text"
              placeholder="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              required
              placeholder="password.."
              autoComplete="off"
              className="form-control"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="repassword" className="form-label">
              Re Password
            </label>
            <input
              required
              placeholder="re password"
              autoComplete="off"
              className="form-control"
              type="password"
              name="repassword"
              value={formData.repassword}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <button className=" btn btn-success" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default StoreForm;

import React, { useEffect, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import UnitRegForm from "../components/UnitRegForm";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const EMBUnits = ({ authToken }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const [open, setOpen] = useState(false);
  const handleCloseSnackbar = () => setSnackbar(null);
  const ToggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const Unit = async () => {
      try {
        const response = await api.get("units/emb", {
          headers: {
            token: authToken,
          },
        });
        setUnits(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error making request:", error);
        setSnackbar({ children: error.message, severity: "error" });
      }
    };
    Unit();
  }, [authToken]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    try {
      const response = await api.put(
        `units/emb/${newRow.id}`,
        { newRow },
        {
          headers: {
            token: authToken,
          },
        }
      );

      setSnackbar({ children: response.data, severity: "success" });
    } catch (error) {
      console.error("Error making request:", error);
    }
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const deleteButton = (id) => async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this unit?"
    );
    if (confirm) {
      setUnits(units.filter((row) => row.id !== id));
      try {
        const response = await api.delete(`units/emb/${id}`, {
          headers: {
            token: authToken,
          },
        });

        setSnackbar({ children: response.data, severity: "success" });
      } catch (error) {
        console.error("Error making request:", error);
        setSnackbar({ children: error.response.data, severity: "error" });
      }
    } else {
      setSnackbar({
        children: "operation canceled on your request",
        severity: "error",
      });
    }
  };
  const handleRowClick = (id) => {
    navigate(`/unitdetail/${id}`);
  };

  const columns = [
    {
      field: "unit_name",
      headerName: "Unit Name",
      width: 140,
      editable: true,
    },
    { field: "address", headerName: "Address", width: 200, editable: true },
    { field: "owner_name", headerName: "Owner", width: 150, editable: true },

    {
      field: "isActive",
      headerName: "is Active",
      type: "boolean",
      width: 140,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 100,

      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),
    },
    {
      field: "updatedAt",
      headerName: "UpDate",
      width: 100,

      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,

      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={deleteButton(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<FileOpenIcon />}
            label="Open"
            onClick={() => handleRowClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid mt-2">
      <div className="shadow-sm p-2 mb-2 rounded bg-body">
        <button
          onClick={() => {
            ToggleOpen();
          }}
          className="btn btn-sm btn-outline-dark"
        >
          <i className="bi bi-chevron-right"></i>
          Add New EM-Unit
        </button>
      </div>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <UnitRegForm
        ToggleOpen={ToggleOpen}
        open={open}
        endpoint={"units/emb"}
        authToken={authToken}
        setSnackbar={setSnackbar}
      />
      <div style={{ height: "80vh", overflowX: "100%" }}>
        <DataGrid
          rows={units}
          columns={columns}
          editMode="row"
          getRowId={(row) => row.id}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing row update:", error);
            setSnackbar({ children: error.message, severity: "error" });
          }}
        />
      </div>
    </div>
  );
};

export default EMBUnits;

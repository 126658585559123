import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import {
  GridRowModes,
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const Delivery = ({ authToken }) => {
  const [rowModesModel, setRowModesModel] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const fetchData = () => {
    api
      .get("units/delivery", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    try {
      const response = await api.put(
        `units/delivery/${newRow.id}`,
        { newRow },
        {
          headers: {
            token: authToken,
          },
        }
      );

      setSnackbar({ children: response.data, severity: "success" });
      fetchData();
    } catch (error) {
      console.error("Error making request:", error);
      setSnackbar({ children: error.response.data, severity: "error" });
    }
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleOpenClick = (id) => {
    navigate(`/fabricdetail/${id}`);
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "fabric_id",
      headerName: "Fabric ID",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <span onClick={() => handleOpenClick(params.row.fabric_id)}>
          <i className="bi bi-chevron-right"></i>
          {params.value}
        </span>
      ),
    },

    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "store",
      headerName: "For Store",
      width: 170,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Velvour", "Vestile-Wear"],
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "art",
      headerName: "Art #",
      width: 150,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "status",
      headerName: "status",
      width: 170,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Dispached", "Delivered", "Received"],
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "sent_pieces",
      type: "number",
      headerName: "Sent Pieces",
      editable: true,
      width: 160,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "received_pieces",
      type: "number",
      headerName: "Received Pieces",
      editable: true,
      width: 160,
      align: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "isDelivered",
      type: "boolean",
      headerName: "Delivered",
      width: 160,
      editable: true,
      align: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "createdAt",
      headerName: "Date",
      width: 170,
      align: "center",
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "updatedAt",
      headerName: "UpDate",
      width: 170,
      align: "center",
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 140,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="container-fluid mt-2">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <div className="d-flex justify-content-center">
        <h4 className="fw-bold shadow-sm p-2 mb-2 rounded bg-body">
          Deliveries Of Units
        </h4>
      </div>

      <div style={{ height: "85vh", overflowX: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              backgroundColor: "rgba(42, 255, 0, 0.5)",
            },
          }}
          rowHeight={25}
          rows={data}
          columns={columns}
          editMode="row"
          getRowId={(row) => row.id}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing row update:", error);
            setSnackbar({ children: error.message, severity: "error" });
          }}
        />
      </div>
    </div>
  );
};

export default Delivery;

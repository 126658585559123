import React from "react";
import SelectComp from "./Select";

function StoreForm({
  formData,
  handleChange,
  handleSubmit,

  viewFabrics,
  users,
  value,
  onChange,
}) {
  return (
    <div className="d-flex justify-content-center">
      <div className="shadow-sm p-3 mb-5 bg-body rounded col-lg-6 col-sm-12">
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary float-end"
          onClick={viewFabrics}
        >
          <i className="bi bi-eye"></i> &nbsp; View Fabrics
        </button>
        <br />
        <br />

        <h4 className="mb-5 text-center">Issue new fabric</h4>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="issued_to" className="form-label">
                Issued To
              </label>
              <SelectComp users={users} value={value} onChange={onChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="meters" className="form-label">
                Measurement
              </label>

              <input
                required
                autoComplete="off"
                className="form-control"
                type="number"
                placeholder="meters...."
                name="meters"
                value={formData.meters}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="for_article" className="form-label">
                For article
              </label>
              <input
                required
                autoComplete="off"
                className="form-control"
                type="text"
                placeholder="vb..."
                name="for_article"
                value={formData.for_article}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <button className=" btn btn-success" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StoreForm;
